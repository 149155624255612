
import Vue from 'vue';
import utils from '../../util';
import { Advertiser, AdvertiserByProduct, Product, Campaign } from '../../types/advertisers';

export default Vue.extend({
  name: 'ProductDetails',
  components: {
    AdvertiserList: () => import('./components/advertiserByProductList.vue'),
    CampaignList: () => import('./components/campaignList.vue'),
  },
  data: (): {
    selectedAdvertiser: string | null;
    selectedProduct: string | null;
    selectedAdvertiserName: string | null;
    search: string;
    tacticData: object[];
    agencyData: object[];
    selectedPartners: string | null;
  } => ({
    selectedAdvertiser: null,
    selectedProduct: null,
    selectedAdvertiserName: null,
    search: '',
    tacticData: [],
    agencyData: [],
    selectedPartners: '',
  }),
  created(): void {
    this.getTactics();
    this.getAdvertisersByProduct();
    this.getAgencies();
  },
  mounted(): void {
    this.clear();
    Vue.nextTick(() => {
      try {
        document.title = 'Advertiser View -  Analytics Dashboard';
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error(exp);
      }
    });
  },
  computed: {
    loading(): boolean {
      return this.$store.state?.advertiserView?.loadingAdvertisers;
    },
    clientList(): Advertiser[] {
      return this.$store.state.advertiserView?.advertisersByProduct;
    },
    productList(): Product[] {
      return this.$store.state.advertiserView?.products;
    },
    campaignList(): Campaign[] {
      return this.$store.state.advertiserView?.campaigns;
    },
    tacticList(): Object[] {
      return this.$store.state.customer?.tacticList;
    },
    error(): string {
      if (this.$store.state.advertiserView?.error) {
        return this.friendlyError(this.$store.state.advertiserView?.error);
      }
      return '';
    },
    superUser(): boolean {
      return utils.hasRight(this.$store.state.customer.user, ['SU']);
    },
    summarySection() {
      const filteredLayout = this.tacticData.filter(section => section.id === 'summary');
      return filteredLayout?.length > 0 ? filteredLayout[0] : null;
    },
  },
  methods: {
    friendlyError(err: string): string {
      if (err?.includes('getAdvertisersByProduct')) {
        return 'No advertisers found';
      } else if (err?.includes('getCampaigns')) {
        return 'No campaigns found for this advertiser';
      }
      return err;
    },
    async updateSearch(advertiser: string): Promise<void> {
      this.search = '';
      await this.clear();
      this.search = advertiser;
      this.getAdvertisersByProduct();
    },
    async changePartner(selectedPartners): Promise<void> {
      await this.clear();
      this.selectedPartners = selectedPartners;
      this.getAdvertisersByProduct();
    },
    async changeTactic(selectedTactic): Promise<void> {
      await this.clear();
      this.selectedProduct = selectedTactic;
      this.getAdvertisersByProduct();
    },
    async clear(): Promise<void> {
      // reset all selections
      await this.$store.dispatch('advertiserView/reset');
      return;
    },
    async getTactics(): Promise<void> {
      if (!this.$store.state.customer?.tacticList) {
        await this.$store.dispatch('getTacticsGQL').catch((error: Error) => {
          console.log(error); // eslint-disable-line no-console
        });
      }
    },
    async getAdvertisersByProduct(): Promise<void> {
      await this.$store
        .dispatch('advertiserView/getAdvertisersByProduct', {
          advertiserName: this.search,
          productName: this.selectedProduct?.toUpperCase(),
          agency: this.selectedPartners,
        })
        .catch();
    },
    sortAgencyList(data): Advertiser[] {
      const array = [...data?.List];
      const sortedArray = array.sort((a, b) => a?.name.localeCompare(b?.name));
      return sortedArray;
    },
    async getAgencies(): Promise<void> {
      if (this.superUser) {
        if (!this.$store.state?.advertiserView?.agencies) {
          await this.$store
            .dispatch('advertiserView/getAgencies')
            .then(data => {
              this.agencyData = this.sortAgencyList(data.getAgencies);
            })
            .catch();
        } else this.agencyData = this.sortAgencyList(this.$store.state.advertiserView.agencies);
      }
    },
    async advertiserSelect(advertiser: AdvertiserByProduct): Promise<void> {
      // await this.clear();
      // get data
      this.getCampaigns(advertiser.name, advertiser.agency_id, this.selectedProduct);
      this.selectedAdvertiser = advertiser.id;
      this.selectedAdvertiserName = advertiser.Name;
    },
    async getCampaigns(advertiserName: string, agencyName: string, product: string): Promise<void> {
      await this.$store.dispatch('advertiserView/getCampaigns', { advertiserName, agencyName, product }).catch();
    },
    openAdvertiser(advertiser: Advertiser): void {
      const query = {
        id: advertiser.id,
        tab: 'orderlist',
      };
      // open new window to avoid losing context
      const routeData = this.$router.resolve({ name: 'home', query });
      window.open(routeData.href, '_blank');
    },
    getCampaignTactic(campaign: Campaign): string {
      if (this.selectedProduct) {
        return this.selectedProduct.toLowerCase() === 'siteimpact' ? 'emailsi' : this.selectedProduct.toLowerCase();
      }
      return campaign.campaignType.toLowerCase() === 'siteimpact' ? 'emailsi' : campaign.campaignType.toLowerCase();
    },
    selectedCampaign(campaign: Campaign): void {
      const query = {
        id: this.selectedAdvertiser,
        tab: this.selectedProduct ? this.selectedProduct.toLowerCase() : campaign.campaignType.toLowerCase(),
        viewCampaigns: campaign.campaignId,
      };
      // open new window to avoid losing context
      const routeData = this.$router.resolve({ name: 'home', query });
      window.open(routeData.href, '_blank');
    },
  },
});
